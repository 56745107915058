import MainNamespace from 'vondutch/globalComponents/MainNamespace';

import heroCarousel from 'client/components/homepage/HeroCarousel';

import passwordStrength from 'client/components/forms/commonElements/PasswordStrength';

const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    heroCarousel,
    passwordStrength
});

mainNamespace.init();
