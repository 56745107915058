import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    'MORE_BTN': '.js-more-less-expand',
    'LESS_BTN': '.js-more-less-collapse',
    'ITEMS': '.js-more-less-item',
    'MORE_LESS_CONTAINER': '.js-more-less-container'
};

export default class MoreLessFilter extends Component {
    init() {
        this.$moreBtn = this.$el.find(SELECTORS.MORE_BTN);
        this.$lessBtn = this.$el.find(SELECTORS.LESS_BTN);
        this.$moreLessContainer = this.$el.find(SELECTORS.MORE_LESS_CONTAINER);

        this.bindEvent('click', `${SELECTORS.MORE_BTN}, ${SELECTORS.LESS_BTN}`, () => this.toggleState());
        this.emitter.addListener('refinement.dropdown.open', (id, topPos) => this.makeHalfOpacity(topPos));
        this.emitter.addListener('refinement.dropdown.close', () => this.makeDefaultOpacity());
        this.emitter.addListener('globalClick', () => this.makeDefaultOpacity());
    }

    toggleState() {
        this.$moreBtn.toggleClass(CLASSES.hide);
        this.$lessBtn.toggleClass(CLASSES.hide);
        this.$el.find(SELECTORS.ITEMS).toggleClass(CLASSES.hide);
    }

    makeHalfOpacity(topPos) {
        if (this.$moreLessContainer.offset().top > topPos) {
            this.$moreLessContainer.addClass(CLASSES.placedBelowOpened);
        } else {
            this.makeDefaultOpacity();
        }
    }

    makeDefaultOpacity() {
        if (this.$moreLessContainer.hasClass(CLASSES.placedBelowOpened)) {
            this.$moreLessContainer.removeClass(CLASSES.placedBelowOpened);
        }
    }
}
