import BaseCheckoutMgr from 'client/components/checkout/CheckoutMgr';
import $ from 'jquery';
import ajax from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';

const SHIPPING_STEP = 'shipping';
const PAYMENT_STEP = 'payment';
const SELECTORS = {
    mgr: '#checkout-mgr',
    forbidden: '.js-shipping-country-forbidden',
    shippingSubmit: '.js-checkout-step-submit[value=submit-shipping]',
    netCountriesMsg: '.js-net-countries-msg'
};

export default class CheckoutMgr extends BaseCheckoutMgr {
    initEvents() {
        // eslint-disable-next-line no-restricted-syntax
        for (const [id, item] of this.components) {
            item.addListener('checkout.step.edit', editFromStep => this.onEditStep(id, editFromStep));
            item.addListener('checkout.step.submitted', (r) => this.verifyStep(r));
            item.addListener('checkout.step.error', (error) => this.error(error));
        }

        let shippingStepCmp = this.components.get(SHIPPING_STEP);

        if (shippingStepCmp) {
            shippingStepCmp.addListener('checkout.step.updated', data => this.onStepUpdated(data));
        }

        this.emitter.addListener('checkout.mgr.reloaded', this.onMgrReloaded.bind(this));
        this.emitter.addListener('checkout.shipping.forbidden.msg', this.onShippingCountryForbidden.bind(this));
        this.emitter.addListener('step.payment.updated', this.onPaymentStepUpdated.bind(this));
        this.emitter.addListener('checkout.step.shipping.submitted', this.onPaymentStepUpdated.bind(this));
        this.emitter.addListener('shippingRates.error', () => this.onShippingRateError());
        this.emitter.addListener('step.shipping.method.changed', () => this.onShippingMethodChanged());
        this.emitter.addListener('step.shipping.country.changed', () => this.onShippingMethodChanged());
        this.emitter.addListener('lock.placeorder.button', () => this.lockPlaceOrderButton());
        this.emitter.addListener('unlock.placeorder.button', () => this.unlockPlaceOrderButton());

        ajax.registerDataFilter(this.onRedirectResponse.bind(this));
    }

    onEditStep(id, editFromStep) {
        this.gotoStep(id, true, editFromStep);
        this.emitter.emit('trbo.checkout.step');
    }

    onNextStage() {
        this.emitter.emit('checkout.step.submitted');
        this.nextStage();
        this.emitter.emit('trbo.checkout.step');
    }

    onShippingRateError() {
        if (this.currentStage !== SHIPPING_STEP) {
            this.gotoStep(SHIPPING_STEP);
        }
    }

    onPaymentStepUpdated(response) {
        if (response && response.order) {
            let $netMsg = this.$el.find(SELECTORS.netCountriesMsg);

            $netMsg.toggleClass(CLASSES.hide, response.order.totals.grossCountry);
        }
    }

    // Used when we cannot directly send customer to Place Order step after editing shipping step
    // because selected payment method is not valid for selected country
    verifyStep(response) {
        if (response && response.order && response.order.paymentMethodInvalid) {
            this.gotoStep(PAYMENT_STEP, true);
        } else {
            this.nextStage();
            this.emitter.emit('trbo.checkout.step');            
        }
    }

    // eslint-disable-next-line class-methods-use-this
    onRedirectResponse(rawData, type) {
        if (type === 'json') {
            let data = JSON.parse(rawData);

            if (data.error && data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        }

        return rawData;
    }

    onMgrReloaded() {
        let shippingCmp = this.components.get(SHIPPING_STEP);

        if (shippingCmp && shippingCmp.form) {
            shippingCmp.form.loopAllNestedComponents(cmp => {
                if ('clearError' in cmp) {
                    cmp.clearError();
                }
                if (cmp.id.indexOf('postalCode') !== -1 && typeof cmp.validate === 'function' && cmp.getValue()) {
                    cmp.validate();
                }
            });

            if (this.currentStage !== SHIPPING_STEP) {
                this.gotoStep(SHIPPING_STEP, true);
            }
        }
    }

    async onStepUpdated(html) {
        let $html = $('<div/>').append(html).find(SELECTORS.mgr);

        this.emitter.emit('namespace.component.destroyall', this.$el.parent(), {
            onAfterDestroy: () => {
                this.$el.html('');
                this.$el.html($html.html());
                this.emitter.emit('namespace.component.initall', this.$el.parent(), {
                    onAfterInit: () => {
                        this.emitter.emit('checkout.mgr.reloaded');
                    }
                });
            }
        });
    }

    onShippingCountryForbidden(isForbidden) {
        let $msgWrapper = this.$el.find(SELECTORS.forbidden);
        let $shippingSubmitButton = this.$el.find(SELECTORS.shippingSubmit);

        $msgWrapper.toggleClass(CLASSES.hide, !isForbidden);
        $shippingSubmitButton.prop('disabled', isForbidden);
    }

    onShippingMethodChanged() {
        this.editFrom = null;
    }
}
