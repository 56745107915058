import BaseShippingStep from 'client/components/checkout/ShippingStep';
import ajax from 'client/utils/ajax';
import { appendParamsToURL } from 'client/utils/url';
import localization from 'vondutch/utils/localization';
import $ from 'jquery';

const CONTENT = {
    'METHOD': '.js-component-content-shipping-method',
    'SHIPMENT': '.js-component-content-shipment-selector',
    'HEADING': '.js-component-content-method-heading'
};

export default class ShippingStep extends BaseShippingStep {
    init () {
        super.init();

        this.emitter.addListener('checkout.shipping.region.changed', data => this.onRegionChanged(data));
        this.emitter.addListener('checkout.shipping.methods.updated', data => this.onMethodsUpdated(data));
        this.isSubmitted = false; // used in order to prevent double submits during ajax calls
    }

    onMethodsUpdated(data) {
        this.replaceHtml(this.templates.method({
            shipping: data.shipping,
            addressForm: data.form
        }), this.$containers.method);
    }

    onMethodChanged(id) {
        id = this.getMethodType(id);
        let isPickUpStationAddressExist = this.$containers.shipment.find('.js-pick-up-station-tab').length > 0;
        let showPickUpStationTitle = id === 'pick-up-station' && isPickUpStationAddressExist;

        setTimeout(() => {
            this.replaceHtml(
                this.templates.heading({
                    method: id,
                    isSavedPickUpAddress: showPickUpStationTitle
                }),
                $(CONTENT.HEADING)
            );
        }, 0);
    }

    onRegionChanged(data) {
        let { oldRegion, newRegion, form, country } = data;
        let options = data.options || {};
        let params = {
            'oldRegion': oldRegion,
            'newRegion': newRegion,
            'countryCode': country,
            'newAddress': true
        };

        let selectedAddressID = options.selectedAddressID;

        if (selectedAddressID && (selectedAddressID !== 'new')) {
            params.selectedAddressID = selectedAddressID;
        }

        if (options.enforced) {
            params.enforcedCountryCodeParam = options.enforced;
        }

        let promise = ajax.load({
            url: appendParamsToURL(this.config.changeRegionUrl, params),
            type: 'POST',
            data: form.serialize()
        });

        promise.then(response => {
            this.emitter.emit('gtmChangeCountryEvent',
                { country: country, language: options.language || '' }
            );
            this.emit('checkout.step.updated', response);
        });
    }

    async onSubmit() {

        this.emitter.emit('prevent.multiple.clicks');

        if (this.isSubmitted) { // preventing double submit
            return;
        }

        let { submitted, form, addressId } = await this.form.onSubmit();
        let region,
            countryCode;

        if (!addressId) {
            this.form.iterateNestedComponents((cmp) => {
                if (cmp.id === 'shipping-countryCode' && this.form.isCmpActive(cmp)) {
                    countryCode = cmp.getValue();

                    if (countryCode) {
                        region = localization.getRegionByCountry(countryCode);
                    }
                }
            }, true);
        }

        if (!region) {
            region = localization.getDefaultRegion();
        }

        let params = {
            region: region,
            country: countryCode
        };

        if (addressId) {
            params.addressId = addressId;
        }

        if (submitted) {
            this.isSubmitted = true;

            let promise = ajax.getJson({
                url: appendParamsToURL(form.data('editAddressUrl'), params),
                type: 'POST',
                data: form.serialize()
            });

            promise.then((resp) => {
                this.isSubmitted = false;

                if (resp.error && resp.errorStage) {
                    if (resp.shippingRatesError) {
                        this.emitter.emit('checkout.shipping.infoblock.show', {
                            type: 'error',
                            scrollTo: true,
                            message: resp.errorMsg
                        });
                    } else {
                        this.emit('checkout.step.error', resp.errorStage);
                    }
                } else {
                    this.emitter.emit('checkout.shipping.infoblock.hide');

                    if (this.gtmEnabled) {
                        this.trackCheckoutShipping(resp);

                        if (resp.subscription) {
                            this.trackSubscribe(resp.subscription);
                        }
                    }

                    this.emitter.emit('checkout.step.shipping.submitted', resp);
                    this.emit('checkout.step.submitted', resp);
                }
            });
        }
    }
}
