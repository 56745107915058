import BaseCheckoutAddressForm from 'client/components/forms/CheckoutAddressForm';
import localization from 'vondutch/utils/localization';
import { CLASSES, ADDRESS_TYPES, SHIPPING_METHODS } from 'client/utils/globals';
import prefs from 'sitePreferences';
import { getHMACToken } from 'urls';
import { getJson, getCSRFToken } from 'client/utils/ajax';
import { appendParamToURL, appendParamsToURL } from 'client/utils/url';
import $ from 'jquery';

const SHIPPING_PREFIX = 'shipping-';
const SELECTORS = {
    'states': {
        'CA': '.js-state-field-CA',
        'US': '.js-state-field-US',
        'billingCA': '[data-id="billing-stateCode-CA"]',
        'billingUS': '[data-id="billing-stateCode-US"]'
    },
    'SHIPPING_COUNTRY_CODE': '.js-shipping-country-code',
    'CONTACT_FORM': '.js-contact-info-form-content',
    'ERROR': '.js-error',
    'SHIPPING_FORM': '.js-shipping-address-form-content',
    'BILLING_FORM': '.js-billing-address-form-content',
    'SAVE_ADDRESS': '.js-save-address',
    'INPUT_FIELD': '.js-field',
    'ADDRESS_TAB': '.js-shipping-form-tab',
    'SELECTED_TAB': '.js-selected-tab',
    'USE_AS_BILLING': '.js-use-as-billing',
    'CARRIER': '.js-carrier-type',
    'CARRIER_INPUT': '.js-carrier-type .js-carrier-input',
    'ESTIMATED_DELIVERY': '.js-estimated-delivery',
    'FIELD': '.js-field',
    'FIELD_CMP': '.js-field-cmp',
    'ADDRESSWRAP': '.js-shipping-address',
    'SELECTED_SHIPMENT': 'input.js-shipment[data-selected=true]',
    'SHIPMENT': 'input.js-shipment'
};

const ADDRESS_FIELDS = [
    'id',
    'title',
    'firstName',
    'lastName',
    'address1',
    'address2',
    'city',
    'street',
    'suite',
    'countryCode',
    'postalCode',
    'phone',
    'postNumber',
    'packstationNumber',
    'postOfficeNumber',
    'snipesStore',
    'stateCode',
    'carrierStationId',
    'carrierStationName'
];

const ADDRESS_SUMMARY_FIELDS_DEFAULT = [
    ['firstName', 'lastName'],
    ['street', 'suite'],
    ['address2'],
    ['stateCode', 'postalCode', 'city'],
    ['countryCode']
];

const EXCLUDED_WATCH_FORM_FIELDS = [
    'shipping-title-select',
    'shipping-countryCode',
    'shipping-phone'
];

export default class CheckoutAddressForm extends BaseCheckoutAddressForm {
    init () {
        this.$states = {
            CA: this.$el.find(SELECTORS.states.CA),
            US: this.$el.find(SELECTORS.states.US)
        };
        this.$contactForm = this.$el.find(SELECTORS.CONTACT_FORM);
        this.selectedMethod = this.config.selectedMethod;
        this.$selectedAddressTab = this.$el.find(`${SELECTORS.ADDRESS_TAB}${SELECTORS.SELECTED_TAB}`);
        this.$shippingAddressForm = this.$el.find(SELECTORS.SHIPPING_FORM);
        this.$saveAddress = this.$el.find(SELECTORS.SAVE_ADDRESS);
        this.$error = this.$el.find(SELECTORS.ERROR);
        this.$carrier = this.$el.find(SELECTORS.CARRIER);
        this.$billingAddressForm = this.$el.find(SELECTORS.BILLING_FORM);
        this.$contactForm = this.$el.find(SELECTORS.CONTACT_FORM);
        this.$estimatedDelivery = this.$el.find(SELECTORS.ESTIMATED_DELIVERY);
        this.$addressWraper = this.$el.find(SELECTORS.ADDRESSWRAP);
        this.$useAsBilling = this.$el.find(SELECTORS.USE_AS_BILLING);
        this.$packStationField = this.getNestedComponentById('shipping-packstationNumber');
        this.$postOfficeField = this.getNestedComponentById('shipping-postOfficeNumber');
        this.$postNumberField = this.getNestedComponentById('shipping-postNumber');
        this.$carrierStationIdField = this.getNestedComponentById('shipping-carrierStationId');
        this.$carrierStationNameField = this.getNestedComponentById('shipping-carrierStationName');

        this.shipmentSelectorCmp = this.getNestedComponentById('shipment-selector');
        this.addressSaveBtn = this.getNestedComponentById('shipping_saveAddress');

        this.bindEvent('change', SELECTORS.CARRIER_INPUT, el => {
            this.$estimatedDelivery.empty().text($(el).data('delivery-msg'));
        });
        this.$el.find(`${SELECTORS.CARRIER_INPUT}:checked`).trigger('change');

        let $selectedShippingAddress = this.$el.find(SELECTORS.SELECTED_SHIPMENT);

        this.addressId = $selectedShippingAddress.data('id');
        this.isNewAddress = this.addressId === 'new';

        if (!this.isNewAddress) {
            this.emitter.emit('shippingRates.address.changed', $selectedShippingAddress.data());
        }

        this.initEvents();
        this.fillShipping(this.shipmentSelectorCmp.getSelectedShipment());
        this.updateFormFields();

        this.useAsBillingCmp = this.getUseAsBillingCmp();

        this.toggleBillingForm();
        this.fillSummary();
    }

    updateFormFields() {
        super.updateFormFields();

        let methodID = this.getMethodType(this.selectedMethod);

        this.$carrier.toggleClass(CLASSES.hide, methodID !== SHIPPING_METHODS.HOME_DELIVERY);
    }

    initEvents() {
        let billingCmp = this.getNestedComponentById('billing-countryCode');

        this.onBillingCountryChanged(billingCmp ? billingCmp.getValue() : '');
        this.emitter.addListener('checkout.shipping.country.changed', this.onShippingCountryChanged.bind(this));
        this.emitter.addListener('checkout.billing.country.changed', this.onBillingCountryChanged.bind(this));
        this.emitter.addListener('step.shipping.address.changed', f => {
            this.toggleError();
            this.fillShipping(f);
            this.toggleAddressSaveButton(f.id);
            this.onShipmentSelectorChanged(f);
        });
        this.emitter.addListener('useshippingasbilling.changed', this.toggleBillingForm.bind(this));
        this.emitter.addListener('step.shipping.method.changed', data => {
            this.toggleError();
            this.onMethodChanged(data.id, data.changedByEvent);
            this.setSelectedShippingMethod(data.shipmentMethodName);
        });
        this.emitter.addListener('checkout.step.shipping.finder', () => this.initFinderFields());
        this.emitter.addListener('modal.closed', p => this.onModalClosed(p));

        this.iterateNestedComponents(cmp => {
            if (cmp.id === 'shipping-stateCode' && this.isCmpActive(cmp)) {
                this.stateCmp = cmp;
                this.stateCmp.addListener('changed', (state) => this.onShippingStateChanged(state));
                if (typeof this.stateCmp.getValue === 'function') {
                    this.emitter.emit('checkout.shipping.state.CA', this.stateCmp.getValue() !== 'CA');
                }
            }
        }, true);

        this.emitter.emit('globalCustomSelect.billing-stateCode-CA-select.enable');
        this.emitter.emit('globalCustomSelect.billing-stateCode-US-select.enable');

        this.initFormFieldsWatcher();
    }

    initFormFieldsWatcher() {
        this.watchedCmp = [];

        this.iterateNestedComponents(cmp => {
            if (this.isShippingFormComponent(cmp) && EXCLUDED_WATCH_FORM_FIELDS.indexOf(cmp.id) === -1) {
                cmp.$el.on('change', this.onFormFieldChanged.bind(this));
                this.watchedCmp.push(cmp);
            }
        }, true);

        this.onFormFieldChanged();
    }

    isShippingFormComponent (cmp) {
        return cmp && cmp.config.id && cmp.config.id.indexOf(SHIPPING_PREFIX) !== -1 && this.isCmpActive(cmp);
    }

    onFormFieldChanged() {
        let shippingComponents = [];
        let validated = [];

        this.iterateNestedComponents(cmp => {
            if (this.isShippingFormComponent(cmp) && this.isCmpActive(cmp) && typeof cmp.validate === 'function') {
                shippingComponents.push(cmp);

                if (cmp.id === 'shipping-countryCode') {
                    return;
                }

                validated.push(cmp.validate());

                if (cmp.id.indexOf('postalCode') === -1 || !cmp.getValue()) {
                    cmp.clearError();
                }
            }
        }, true);

        if (validated.every(item => item)) {
            let address = Object.create(null);

            shippingComponents.forEach(cmp => {
                let key = cmp.config.id.replace(SHIPPING_PREFIX, '');

                if (cmp.getValue() !== '') {
                    address[key] = cmp.getValue();
                }
            });

            this.emitter.emit('shippingRates.address.changed', address);
        }
    }

    async updateContactForm(countryCode = null) {
        let url = this.config.contactFormUrl;
        let contactFields = Object.create(null);

        if (countryCode) {
            url = appendParamToURL(url, 'countryCode', countryCode);
        }

        this.iterateNestedComponents((cmp) => {
            if (cmp.id && cmp.id.indexOf('contact-') !== -1 && typeof cmp.getValue === 'function') {
                contactFields[cmp.id.replace('contact-', '')] = cmp.getValue();
            }
        }, true);

        if (Object.keys(contactFields).length > 0) {
            url = appendParamsToURL(url, contactFields);
        }

        let response = await getJson({ url: url });

        this.emitter.emit('namespace.component.destroyall', this.$contactForm, {
            onAfterDestroy: () => {
                this.$contactForm.html($('<div/>').append(response.contactForm).html());
                this.emitter.emit('namespace.component.reinitall', this.$contactForm, {
                    onAfterInit: () => {
                        this.emitter.emit('checkout.shipping.forbidden.msg', response.shippingCountryForbidden);
                    }
                });
            }
        });
    }

    async onShipmentSelectorChanged(fields) {
        if (fields.id === 'new') {
            this.isNewAddress = true;
            this.updateContactForm();
        } else {
            this.isNewAddress = false;
            this.addressId = fields.id;
            await this.updateContactForm(fields.countryCode);

            if (['pick-up-station'].indexOf(this.selectedMethod.split('_')[0]) === -1 && fields.event) {
                this.emitter.emit('shippingRates.address.changed', fields);
            }
        }
    }

    onShippingStateChanged(state) {
        this.emitter.emit('checkout.shipping.state.CA', state !== 'CA');
    }

    fillShipping(fields) {
        // Show/Hide form according to selected address type
        // If new address then show clear form
        // Otherwise hide it and prefill fields
        this.toggleCarrierFields(fields);
        this.$shippingAddressForm.toggleClass(CLASSES.collapsed, fields.id !== 'new');
        this.toggleShippingForm(fields.id !== 'new');

        this.iterateNestedComponents((cmp) => {
            if (!cmp || !cmp.id || cmp.id.indexOf(SHIPPING_PREFIX) === -1) {
                return;
            }

            let key = cmp.id.replace(SHIPPING_PREFIX, '');

            if (key !== 'id' && this.isCmpActive(cmp)) {
                if (fields[key]) {
                    if (fields.isEntireNew) {
                        cmp.setValue(fields[key], false);
                        cmp.clearError();
                    } else {
                        cmp.setValue(fields[key], key === 'countryCode' ? true : !fields[key]);
                    }
                } else if (ADDRESS_FIELDS.indexOf(key) !== -1 && key !== 'countryCode') {
                    cmp.setValue(null, true);
                }
            }
        }, true);

        this.fillAddress1('shipping');
        this.fillCarrier(fields);
        this.onShippingStateChanged(fields.stateCode ? fields.stateCode.toUpperCase() : null);
    }

    toggleCarrierFields(fields) {
        if (!fields.carrier && !fields.carrierName) {
            return;
        }
        let carrierName = fields.carrier || fields.carrierName;

        this.$packStationField.setValue('');
        this.$postOfficeField.setValue('');
        this.$carrierStationIdField.setValue('');
        this.$carrierStationNameField.setValue('');
        if (carrierName === 'dhl') {
            this.$postNumberField.config.required = true;
            this.$carrierStationIdField.config.required = false;
            this.$carrierStationNameField.config.required = false;
        } else if (carrierName === 'hermes') {
            this.$postNumberField.setValue('');
            this.$postNumberField.config.required = false;
            this.$carrierStationIdField.config.required = true;
            this.$carrierStationNameField.config.required = true;
        }
    }

    onShippingCountryChanged(country, options = {}) {
        // check if country was changed
        if (!options.enforced && (!country || country === this.config.currentCountry)) {
            return;
        }

        let oldRegion = localization.getRegionByCountry(this.config.currentCountry);
        let newRegion = localization.getRegionByCountry(country);

        this.emitter.emit('checkout.shipping.region.changed', {
            form: this.$el,
            newRegion: newRegion,
            oldRegion: oldRegion,
            country: country,
            options: options
        });
    }

    onBillingCountryChanged (country) {
        switch (country) {
            case 'US':
                this.$states.US.removeClass(CLASSES.hide).find('select').removeAttr('disabled');
                this.emitter.emit('globalCustomSelect.billing-stateCode-US-select.enable');
                break;
            default:
                this.$states.US.addClass(CLASSES.hide).find('select').attr('disabled', 'disabled');
                break;
        }

        this.validatePostalCode(ADDRESS_TYPES.billing);
    }

    copyShippingToBilling() {
        let shippingCountryField = this.getNestedComponentById('shipping-countryCode');
        let shippingCountryCode = shippingCountryField ? shippingCountryField.getValue() : null;

        for (let field of ADDRESS_FIELDS) {
            let billingKey = `billing-${field}`;

            if (field === 'stateCode' && ['US', 'CA'].indexOf(shippingCountryCode) !== -1) {
                billingKey += `-${shippingCountryCode}`;
            }

            let shippingField = this.getNestedComponentById(`shipping-${field}`),
                billingField = this.getNestedComponentById(billingKey);

            if (shippingField && billingField) {
                billingField.setValue(shippingField.getValue());
            }
        }

        // this.onBillingCountryChanged(shippingCountryCode); not sure
    }

    getAddressSummaryFromSavedAddress(addressId, fields) {
        let mappedFields = [];
        let addressFields = this.$el.find(`${SELECTORS.SHIPMENT}[data-id=${addressId}]`).data();
        const PICK_UP_STATION_FIELDS = {
            'packstationNumber': 'Packstation',
            'postOfficeNumber': 'PostOffice',
            'carrierStationId': 'CarrierStationId'
        };

        if (!addressFields) {
            return '';
        }

        for (let fieldRow of fields) {
            let value = [];

            fieldRow.forEach((field) => {
                if (field === 'countryCode') {
                    let option = this.$el.find(`option[id=${addressFields[field]}]`);

                    value.push(option && option.length ? option[0].label : '');
                } else if (addressFields[field] && PICK_UP_STATION_FIELDS[field]) {
                    value.push(this.config['storeType' + PICK_UP_STATION_FIELDS[field]]);
                    value.push(addressFields[field]);
                } else {
                    value.push(addressFields[field]);
                }
            });

            mappedFields.push(value.filter(item => item !== '' && item !== null).join(' '));
        }

        return mappedFields.filter(item => item !== '' && item !== null).join(', ');
    }

    // eslint-disable-next-line consistent-return
    async fillSummary() {
        if (this.isNewAddress) {
            super.fillSummary();
        } else {
            this.summary = {};
            this.summary.shipping = this.getAddressSummaryFromSavedAddress(
                this.addressId, this.getAddressSummaryFields()
            );
            let carrier = this.getCarrier();
            let selectedShippingMethod = this.getSelectedShippingMethod();

            if (carrier) {
                this.summary.carrier = carrier + ' ' + selectedShippingMethod;
            }

            this.useAsBillingCmp = this.getUseAsBillingCmp();
            if ((this.useAsBillingCmp && !this.useAsBillingCmp.getValue()) || !this.useAsBillingCmp) {
                this.summary.billing = this.mapAddressSummary('billing');
            }

            let emailCmp = this.getNestedComponentById('contact-email');

            if (emailCmp && this.onValidateEmail(emailCmp) && emailCmp.getValue() !== '') {
                const csrfToken = await getCSRFToken({ doNotStopSpinner: true });

                if (!csrfToken) {
                    return {
                        error: true
                    };
                }

                const response = await getJson({
                    type: 'post',
                    url: getHMACToken,
                    data: {
                        email: emailCmp.getValue(),
                        [csrfToken.tokenName]: csrfToken.token
                    }
                });

                this.emitter.emit('emarsys.guest.email.filled', response.cuid);
            }
        }
    }

    setSelectedShippingMethod(methodName) {
        this.config.selectedShippingMethod = methodName;
    }

    getSelectedShippingMethod() {
        return this.config.selectedShippingMethod;
    }

    mapAddressSummary(type, fields = ADDRESS_SUMMARY_FIELDS_DEFAULT) {
        let mappedFields = [];

        for (let fieldRow of fields) {
            mappedFields.push(this.mapFields(type, fieldRow, true));
        }

        mappedFields = mappedFields.filter(item => item !== '' && item !== null);

        return mappedFields.join(', ');
    }

    getAddressSummaryFields() {
        let method = this.selectedMethod;
        let ADDRESS_SUMMARY_FIELDS = [
            ['firstName', 'lastName']
        ];
        const PICK_UP_STATION_FIELDS = {
            'packstationNumber': 'Packstation',
            'postOfficeNumber': 'PostOffice',
            'carrierStationId': 'CarrierStationId'
        };

        ADDRESS_SUMMARY_FIELDS.push(['street', 'suite']);

        if ((new RegExp(prefs.shippingMethods.pickUpStation)).test(method)) {
            for (const key in PICK_UP_STATION_FIELDS) { // eslint-disable-line no-restricted-syntax
                if (Object.prototype.hasOwnProperty.call(PICK_UP_STATION_FIELDS, key)) {
                    let addressField = this.$el.find(`${SELECTORS.ADDRESS_TAB}[data-id="${this.selectedMethod}"] ` +
                    `[data-id="${'shipping'}-${key}"]`).data('cmp-instance');

                    if (addressField && addressField.getValue()) {
                        this.storeType = this.config['storeType' + PICK_UP_STATION_FIELDS[key]];
                    }
                }
            }
            ADDRESS_SUMMARY_FIELDS.push(['postNumber']);
            ADDRESS_SUMMARY_FIELDS.push(['storeType', 'packstationNumber', 'postOfficeNumber', 'carrierStationId']);
        } else if ((new RegExp(prefs.shippingMethods.shipToStore)).test(method)) {
            this.storeType = this.config.storeTypeShipToStore;
            ADDRESS_SUMMARY_FIELDS.push(['storeType', 'snipesStore']);
        } else if ((new RegExp(prefs.shippingMethods.homeDelivery)).test(method)) {
            ADDRESS_SUMMARY_FIELDS.push(['address2']);
        } else {
            ADDRESS_SUMMARY_FIELDS.push(['address2']);
        }

        ADDRESS_SUMMARY_FIELDS.push(['stateCode', 'postalCode', 'city']);
        ADDRESS_SUMMARY_FIELDS.push(['countryCode']);

        return ADDRESS_SUMMARY_FIELDS;
    }

    mapFields(type, fieldRow, nested) {
        let separator = nested ? ' ' : ', ';

        let value = [];

        fieldRow.forEach(field => {
            if (field === 'storeType') {
                value.push(this.storeType);
            } else {
                let cmpId = `${type}-${field}`;

                if (type === 'billing' && field === 'stateCode') {
                    let countryCode = this.getFormCountry('billing').value;

                    cmpId += `-${countryCode}`;
                }

                this.iterateNestedComponents(addressField => {
                    if (addressField.id === cmpId && this.isCmpActive(addressField) &&
                    addressField.disabled === false) {
                        let newValue = addressField && addressField.getValue() ? addressField.getValue() || '' : '';

                        if (field === 'countryCode' && newValue && type === 'shipping') {
                            let formCountry = this.getFormCountry('shipping');

                            if (formCountry.title) {
                                newValue = formCountry.title;
                            } else {
                                let input = this.$selectedAddressTab.find(SELECTORS.SHIPPING_COUNTRY_CODE);

                                newValue = input && input.length ? input.val() : '';
                            }
                        } else if (field === 'countryCode' && newValue && type === 'billing') {
                            let option = addressField.$el.find(`option[id=${newValue}]`);

                            newValue = option && option.length ? option[0].label : '';
                        }

                        value.push(newValue);
                    }
                }, true);
            }
        });

        return value.filter(val => val !== '' && val !== null).join(separator);
    }

    /**
     * Getting shipping or billing country object
     * @param {string} type value: "shipping" or "billing"
     */
    getFormCountry(type) {
        let cmpID = `${type}-countryCode`;
        let countryObject = { title: '', value: '' };

        this.iterateNestedComponents(cmp => {
            if (cmp.id === cmpID && this.isCmpActive(cmp)) {
                let $field = cmp.$field;

                let $option = $field.children('option:selected');

                if ($option) {
                    countryObject.title = $option.text();
                    countryObject.value = $option.val();
                }
            }
        });

        return countryObject;
    }

    _isPartOfValidation (id, validationArr) {
        return validationArr.some(item => id.indexOf(item) !== -1);
    }

    async onSubmit() {
        if (this.isNewAddress) {
            return await super.onSubmit();
        } else {
            let formsToValidate = ['contact-'];

            if (this.useAsBillingCmp && this.useAsBillingCmp.getValue()) {
                this.copyShippingToBilling();
            } else {
                formsToValidate.push('billing-');
                this.fillAddress1('billing');
            }

            let result = await Promise.all(this._nestedComponents.map(cmp => {
                if (typeof cmp.validate === 'function' && this._isPartOfValidation(cmp.id, formsToValidate)) {
                    let validateResult = cmp.validate();

                    return validateResult instanceof Promise ? validateResult : Promise.resolve(validateResult);
                }

                return Promise.resolve(true);
            }));

            this.fillSummary();

            return { submitted: result.every(Boolean), form: this.$el, addressId: this.addressId };
        }
    }

    getUseAsBillingCmp() {
        return this.isHomeDelivery(this.selectedMethod) ?
            this.getNestedComponentById('shipping-useAsBilling') : null;
    }

    destroy () {
        if (this.watchedCmp) {
            this.watchedCmp.forEach(cmp => {
                cmp.$el.off('change', this.onFormFieldChanged.bind(this));
            });
        }

        super.destroy();
    }
}
