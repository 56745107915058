import BaseQuickSearchForm from 'client/components/header/QuickSearchForm';

import { appendParamToURL } from 'client/utils/url';

import $ from 'jquery';
import { debounce } from 'lodash';
import { CLASSES } from 'client/utils/globals';
import { isMedium, isSmall, isCurrentlySmall, isCurrentlyMedium } from 'client/utils/screendetector';
import { isIOS } from 'client/utils/mobileDetect';
import { scrollTo } from 'client/utils/common';
import { handleSearchSuggestion,
    fireSearchSuggestionTag,
    trackSuggestionPressEnter,
    trackSuggestionPressSearchIcon }
    from 'client/components/google/GtmSearchSuggestion';

const DEFAULT_CONFIG = {
    delay: 300,
    symbolsLength: 3,
    searchParamName: 'q'
};

const SELECTORS = {
    searchInput: '.js-search-input',
    clearSearchBtn: '.js-clear-search',
    searchBtn: '.js-search-btn',
    suggestionsWrapper: '.js-suggestions-wrapper',
    searchFormWrap: '.js-search-form-wrapper',
    searchFormInner: '.js-search-form-inner',
    stickyHeader: '.js-sticky-inner',
    headerWrapper: '.js-header',
    searchContainer: '.js-search-container',
    headerBanner: '.js-header-banner'
};

const STATE_CLASSES = {
    searchShow: 'h-search-visible',
    searchShadow: 'c-search-form-inner--shadow',
    searchOpened: 'b-search--opened',
    closeBTtnShow: 'f-clear-search--show',
    headerSticked: 'h-header-sticky',
    searchSuggestionsOpened: 'h-search-suggestions--opened'
};

export default class QuickSearchForm extends BaseQuickSearchForm {
    init () {
        this.config = Object.assign({}, this.config, DEFAULT_CONFIG);

        this.$searchInput = this.$el.find(SELECTORS.searchInput);
        this.$suggestionsWrapper = this.$el.find(SELECTORS.suggestionsWrapper);
        this.$clearSearch = this.$el.find(SELECTORS.clearSearchBtn);
        this.$searchFormWrap = this.$el.find(SELECTORS.searchFormWrap);
        this.$searchFormInner = this.$el.find(SELECTORS.searchFormInner);
        this.$headerWrapper = $(SELECTORS.headerWrapper);
        this.$stickyHeader = $(SELECTORS.stickyHeader);
        this.$headerBanner = $(SELECTORS.headerBanner);
        this.suggestionNotEmpty = false;
        this.suggestionsHidden = true;
        this.isHeaderSticky = false;
        this.isSearchOpened = false;
        this.previousSearchPhrase = '';
        this.isIOSDevice = isIOS();
        this.$searchContainer = $(SELECTORS.searchContainer);

        this.headerStickyDetermination();

        this.bindEvent('click', SELECTORS.clearSearchBtn, this.clearSearch);
        this.bindEvent('click', SELECTORS.searchBtn, this.runSearch);
        this.bindEvent('keyup', SELECTORS.searchInput, debounce(this.onInputTyping, this.config.delay));
        this.bindEvent('focus', SELECTORS.searchInput, this.activateSearchInput);
        this.bindEvent('focus', SELECTORS.suggestionsWrapper, this.activateSearchInput);

        this.addListener('headerIsSticky', this.headerBecameSticky.bind(this));
        this.addListener('headerIsNotSticky', this.headerBecameNotSticky.bind(this));
        this.bindEvent('blur', SELECTORS.searchInput, () => this.blurSearch(this.isSearchOpened));
        this.bindEvent('blur', SELECTORS.suggestionsWrapper, () => this.emitter.emit('enabledScroll'));
        this.emitter.addListener('headerSearchShow', this.searchShow.bind(this));
        this.emitter.addListener('deviceChange', () => this.recalculateHeight());
        this.emitter.addListener('resize', () => this.recalculateHeight());
        this.emitter.addListener('orientationChange', () => this.toggleScroll(true));
        this.emitter.addListener('globalClick', this.globalClick.bind(this));
        this.emitter.addListener('globalWindowBlur', this.globalWindowBlur.bind(this));

        if (this.gtmEnabled) {
            this.emitter.addListener('fireSearchSuggestionTag', fireSearchSuggestionTag.bind(this));
            this.emitter.addListener('handleSearchSuggestion', handleSearchSuggestion.bind(this));
            this.emitter.addListener('trackSuggestionPressEnter', trackSuggestionPressEnter.bind(this));
            this.emitter.addListener('trackSuggestionPressSearchIcon', trackSuggestionPressSearchIcon.bind(this));
            this.keyPressList = [];
            this.bindEvent('keyup', SELECTORS.searchInput, this.trackSuggestionKeyup);
        }
    }

    activateSearchInput () {
        super.activateSearchInput();
        this.$searchInput.attr({
            'placeholder': this.config.placeholderActive,
            'aria-label': this.config.placeholderActive
        });
        this.$headerWrapper.addClass(STATE_CLASSES.searchOpened);
    }

    onInputTyping (input, ev) {
        if (this.gtmEnabled && ((ev.keyCode ? ev.keyCode : ev.which) === 13)) {
            this.emitter.emit('fireSearchSuggestionTag', this.emitter);
        }

        let searchPhrase = this.$searchInput.val().trim();

        if (searchPhrase.length === 0) {
            this.$clearSearch.removeClass(STATE_CLASSES.closeBTtnShow);
        } else {
            this.$clearSearch.addClass(STATE_CLASSES.closeBTtnShow);
        }

        if (this.config.symbolsLength <= searchPhrase.length) {
            if (this.previousSearchPhrase === searchPhrase) {
                return;
            }

            this.emitter.emit('emarsys.search.term', searchPhrase);
            $.ajax({
                url: appendParamToURL(this.config.suggestUrl, this.config.searchParamName, searchPhrase),
                method: 'GET'
            }).then(data => {
                if (typeof data === 'string') {
                    const $html = $(data);

                    this.emitter.emit('namespace.component.initall', $html, {
                        onAfterInit: () => {
                            const $images = $html.find('img'),
                                imgCount = $images.length;

                            let imgLoaded = 0;

                            $images.on('load', () => {
                                if (++imgLoaded === imgCount) {
                                    this.emitter.emit('namespace.component.destroyall', this.$suggestionsWrapper);
                                    this.$suggestionsWrapper.empty().append($html);

                                    this.suggestionNotEmpty = true;
                                    this.showSuggestions();

                                }
                            });

                            if (this.gtmEnabled) {
                                this.emitter.emit('handleSearchSuggestion', $html, this.emitter);
                            }

                        }
                    });
                } else {
                    this.$suggestionsWrapper.empty();
                    this.hideSuggestions();
                }
            });
        } else {
            this.clearSuggestions();
        }

        this.previousSearchPhrase = searchPhrase;
    }

    blurSearch(isOpen) {
        if (isOpen && (isSmall() || isMedium())) {
            if (this.suggestionNotEmpty && !this.suggestionsHidden) {
                setTimeout(() => this.recalculateHeight(), 200);
                return;
            }
            if (this.gtmEnabled) {
                this.emitter.emit('fireSearchSuggestionTag', this.emitter);
            }
            this.emitter.emit('enabledScroll');
            this.isSearchOpened = !isOpen;
        } else {
            this.$searchInput.attr({
                'placeholder': this.config.placeholderInactive,
                'aria-label': this.config.placeholderInactive
            });
        }
    }

    clearSearch (el, e) {
        e.preventDefault();
        this.$searchInput.val('');
        this.clearSuggestions();
        this.$clearSearch.removeClass(STATE_CLASSES.closeBTtnShow);
        this.$searchInput.focus();
        if (this.gtmEnabled) {
            this.emitter.emit('fireSearchSuggestionTag', this.emitter);
        }
    }

    showSuggestions () {
        if (this.suggestionsHidden) {

            this.$suggestionsWrapper.removeClass(CLASSES.hide);
            this.$searchFormInner.addClass(STATE_CLASSES.searchSuggestionsOpened);
            this.recalculateHeight();
            if (isSmall() || isMedium()) {
                this.emitter.emit('disabledScroll');

                if (this.isIOSDevice) {
                    scrollTo(this.$stickyHeader, {
                        duration: 0
                    });
                }
            }
            this.suggestionsHidden = !this.suggestionsHidden;
        } else {
            this.$searchFormInner.removeClass(STATE_CLASSES.searchBorderRadius);
        }
    }

    hideSuggestions () {
        if (!this.suggestionsHidden) {
            this.$suggestionsWrapper.addClass(CLASSES.hide);
            this.$searchFormInner.removeClass(STATE_CLASSES.searchSuggestionsOpened);
            this.suggestionsHidden = !this.suggestionsHidden;
        }

        if (this.gtmEnabled) {
            this.emitter.emit('fireSearchSuggestionTag', this.emitter);
        }
    }

    headerStickyDetermination() {
        if (this.$stickyHeader.parent().hasClass(CLASSES.headerSticky)) {
            this.isHeaderSticky = true;
        }
    }

    recalculateHeight() {
        this.viewPortHeight = $(window).innerHeight();
        this.$headerHeight = this.$stickyHeader.outerHeight();
        this.$headerBannerHeight = this.$headerBanner.outerHeight();
        let height = Math.round(this.viewPortHeight - this.$headerHeight);

        if (!this.isHeaderSticky) {
            height = Math.round(height - this.$headerBannerHeight);
        }

        const isMobile = isSmall() || isMedium();

        if (isMobile && this.suggestionNotEmpty) {
            this.$suggestionsWrapper.css('min-height', height);
        } else {
            this.$suggestionsWrapper.css('min-height', '500px');
        }
    }

    searchShow () {
        this.$searchFormWrap.addClass(STATE_CLASSES.searchShow);
        this.$searchFormInner.addClass(STATE_CLASSES.searchShadow);
        let headerIsSticked = this.$headerWrapper.hasClass(STATE_CLASSES.headerSticked);

        if (headerIsSticked && (isCurrentlySmall() || isCurrentlyMedium())) {
            if (!this.isIOSDevice) {
                this.emitter.emit('disabledScroll');
            }
        }
        this.$headerWrapper.addClass(STATE_CLASSES.searchOpened);
        this.$searchInput.focus();
    }

}
