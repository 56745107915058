import BasePaymentOptions from 'client/components/checkout/PaymentOptions';

const SELECTORS = {
    TAB: '.js-payment-option-tab',
    CONTENT: '.js-payment-option-content',
    PAYMENT_OPTION: 'input[name$=_paymentMethod]',
    INPUT_FIELD: '.js-field',
    PAYMENT_ERROR: '.js-payment-error'
};

export default class PaymentOptions extends BasePaymentOptions {
    init () {
        this.tabs = this.$el.find(SELECTORS.TAB);
        this.contents = this.$el.find(SELECTORS.CONTENT);
        this.paymentOptions = this.$el.find(SELECTORS.PAYMENT_OPTION);
        this.selectedMethod = null;
        this.$paymentError = this.$el.parent().find(SELECTORS.PAYMENT_ERROR);

        this.inactive();
        this.activeSelectedOption();
        this.bindEvent('click', SELECTORS.TAB, (el, ev) => this.active(el, ev));
        this.emitter.addListener('giftCards.updated', (r) => this.toggleRestrictions(r));
        this.emitter.addListener('step.shipping.updated', (r) => this.toggleRestrictions(r));
        this.emitter.addListener('lock.fraud.payment.option', this.lockFraudPaymentOptions.bind(this));
    }

    active(el, event) {
        super.active(el, event);

        this.emitter.emit('checkout.payment.method.changed');
    }
}
