import MainNamespace from 'vondutch/globalComponents/MainNamespace';
import contactUs from 'client/components/forms/ContactUs';

const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    contactUs
});

mainNamespace.init();
