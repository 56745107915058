import MainNamespace from 'vondutch/globalComponents/MainNamespace';

import productGrid from 'client/components/product/ProductGrid';
import ProductTiles from 'client/components/product/ProductTiles';
import gridSort from 'client/components/product/GridSort';
import gridQuantity from 'client/components/product/GridQuantity';
import expandCategory from 'client/components/search/ExpandCategory';
import refinementsWrapper from 'client/components/search/RefinementsWrapper';
import refinementDropdown from 'client/components/search/RefinementDropdown';
import attributeRefinement from 'client/components/search/AttributeRefinement';
import priceRefinement from 'vondutch/components/search/PriceRefinement';
import searchWrapper from 'client/components/search/SearchWrapper';
import moreLessFilter from 'client/components/search/MoreLessFilter';
import filterBar from 'client/components/search/FilterBar';
import refinementMenu from 'vondutch/components/search/RefinementMenu';
import noResultsSearchForm from 'client/components/search/NoResultsSearchForm';
import categoryRefinement from 'client/components/search/CategoryRefinement';
import imageListingCarousel from 'client/components/product/ImageListingCarousel';
import categoryCarousel from 'client/components/search/CategoryCarousel';

const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    productGrid,
    ProductTiles,
    gridSort,
    gridQuantity,
    expandCategory,
    refinementsWrapper,
    refinementDropdown,
    attributeRefinement,
    priceRefinement,
    searchWrapper,
    moreLessFilter,
    filterBar,
    refinementMenu,
    noResultsSearchForm,
    categoryRefinement,
    imageListingCarousel,
    categoryCarousel
});

mainNamespace.init();

