import MainNamespace from 'vondutch/globalComponents/MainNamespace';

import productMgr from 'client/components/product/ProductMgr';
import imageCarousel from 'client/components/product/ImageCarousel';
import photoswipeGallery from 'client/components/product/PhotoswipeGallery';
import variationAttributes from 'client/components/product/VariationAttributes';
import sizingSystemSwitch from 'client/components/product/SizingSystemSwitch';
import availability from 'client/components/product/Availability';
import addToWishListButton from 'client/components/product/AddToWishListButton';
import addToCartProduct from 'client/components/product/AddToCartProduct';
import banner from 'client/components/product/Banner';
import productReviewAnchor from 'client/components/product/ProductReviewAnchor';
import productFacts from 'client/components/product/ProductFacts';
import productComponent from 'client/components/product/ProductComponent';
import findInStoreModal from 'client/components/findInStore/FindInStoreModal';
import findInStoreMgr from 'client/components/findInStore/FindInStoreMgr';
import findInStoreSearchPanel from 'client/components/findInStore/FindInStoreSearchPanel';
import findInStoreProductPanel from 'client/components/findInStore/FindInStoreProductPanel';
import findInStoreMapResult from 'client/components/findInStore/FindInStoreMapResult';
import googleMapsAutocomplete from 'client/components/google/GoogleMapsAutocomplete';
import googleMapsGeocoder from 'client/components/google/GoogleMapsGeocoder';
import googleMap from 'client/components/google/GoogleMap';
import infoBlock from 'client/components/checkout/InfoBlock';
import detailsAccordion from 'client/components/product/DetailsAccordion';
import productStores from 'vondutch/components/product/ProductStores';

const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    productMgr,
    imageCarousel,
    photoswipeGallery,
    variationAttributes,
    sizingSystemSwitch,
    availability,
    addToWishListButton,
    addToCartProduct,
    banner,
    productReviewAnchor,
    productFacts,
    productComponent,
    detailsAccordion,
    productStores,

    googleMapsAutocomplete,
    googleMapsGeocoder,
    googleMap,

    findInStoreModal,
    findInStoreMgr,
    findInStoreSearchPanel,
    findInStoreProductPanel,
    findInStoreMapResult,
    infoBlock
});

mainNamespace.init();
