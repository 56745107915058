import Namespace from 'client/core/Namespace';
import $ from 'jquery';
import { throttle, debounce } from 'lodash';

import headerSlot from 'client/components/header/HeaderContentSlot';
import headerBrowserError from 'client/components/header/HeaderBrowserError';
import accountHeader from 'client/components/header/AccountHeader';
import stickyHeader from 'vondutch/components/header/StickyHeader';
import wishlistHeader from 'client/components/header/WishlistHeader';
import stickyButton from 'client/components/StickyButton';
import stickyButtonTopBottom from 'client/components/StickyButtonTopBottom';
import stickyPanel from 'client/components/product/StickyPanel';
import quickSearchForm from 'vondutch/components/header/QuickSearchForm';
import improvementQuickSearchForm from 'vondutch/components/header/ImprovementQuickSearchForm';
import headerHamburger from 'vondutch/components/header/HeaderHamburger';
import mobileMenuSwitcher from 'client/components/header/MobileMenuSwitcher';
import inputText from 'client/components/forms/commonElements/InputText';
import inputCheckbox from 'client/components/forms/commonElements/InputCheckbox';
import inputHidden from 'client/components/forms/commonElements/InputHidden';
import inputSelect from 'client/components/forms/commonElements/InputSelect';
import submitButton from 'client/components/forms/commonElements/SubmitButton';
import inputTextarea from 'client/components/forms/commonElements/InputTextArea';
import inputAutocomplete from 'client/components/forms/commonElements/InputAutocomplete';
import globalCustomSelect from 'vondutch/components/forms/commonElements/GlobalCustomSelect';
import globalCustomSelectWrap from 'client/components/forms/commonElements/GlobalCustomSelectWrap';
import globalCustomSearchSelect from 'client/components/forms/commonElements/GlobalCustomSearchSelect';
import subscribeForm from 'client/components/forms/SubscribeForm';
import contestForm from 'client/components/forms/ContestForm';
import unsubscribeForm from 'client/components/forms/UnsubscribeForm';
import headerSearchSwitcher from 'client/components/header/HeaderSearchSwitcher';
import miniCart from 'vondutch/components/header/MiniCart';
import cartLineItem from 'client/components/cart/CartLineItem';
import cookieHint from 'client/components/CookieHint';
import backToTop from 'client/components/BackToTop';
import productBrand from 'client/components/product/ProductBrand';
import carousel from 'client/components/Carousel';
import expandBlock from 'client/components/ExpandBlock';
import heroCarousel from 'client/components/homepage/HeroCarousel';
import mediaInteraction from 'client/globalComponents/mediaInteraction';
import gtm from 'client/globalComponents/gtm';
import mobileMenu from 'client/components/MobileMenu';
import mobileMenuToggle from 'client/components/MobileMenuToggle';
import spinner from 'client/components/Spinner';
import justAddedPopup from 'client/components/JustAddedPopup';
import spinnerPreloader from 'client/components/SpinnerPreloader';
import backButton from 'client/components/BackButton';
import modal from 'client/components/Modal';
import modalContainer from 'client/components/ModalContainer';
import recommendations from 'client/components/Recommendations';
import tooltip from 'client/components/Tooltip';
import swatchCarousel from 'client/components/product/SwatchCarousel';
import localizationDialog from 'vondutch/components/LocalizationDialog';
import pickUpStation from 'client/components/PickUpStation';
import pickUpStationModal from 'client/components/PickUpStationModal';
import dataLink from 'client/components/DataLink';
import livereach from 'client/components/product/Livereach';
import classToggle from 'vondutch/components/ClassToggle';
import emarsys from 'client/globalComponents/Emarsys';
import toggleLink from 'client/components/ToggleLink';
import alert from 'client/components/Alert';
import loginForm from 'client/components/forms/LoginForm';
import loginPopup from 'client/components/LoginPopup';
import initGTM from 'client/components/InitGTM';
import mainMenu from 'client/components/header/MainMenu';
import breadcrumbs from 'client/components/Breadcrumbs';
import variationAttributesColor from 'client/components/product/VariationAttributesColor';
import imageStickyPanel from 'client/components/product/ImageStickyPanel';
import checkoutConfirmation from 'client/components/order/confirmation/CheckoutConfirmation';
import trbo from 'client/globalComponents/trbo';
import collapse from 'client/components/Collapse';

import 'client/thirdParty/bootstrap';
import 'client/utils/spinner';

import firstVisitPopup from 'client/components/homepage/FirstVisitPopup';

require('client/utils/screendetector');
// int_emarsys_snipes:int_emarsys_sfra:int_emarsys:plugin_perimeterx:plugin_uniserv:plugin_dhl:plugin_mapp:plugin_sitemap:plugin_wishlists_buffalo:plugin_wishlists_snipes:plugin_wishlists:plugin_service_cloud:int_payment_engine:lib_productlist:app_custom_buffalo:plugin_collectinstore:app_refapp:plugin_shipping_rates_ups:plugin_shipping_rates_dhl:int_service_cloud:app_storefront_base:app_arch:bc_job_components:plugin_stockreservation:plugin_data_deliveries:plugin_melissa:bc_transformation_layer:ds_console:plugin_arvato:bc_akeneo
// int_emarsys_snipes:int_emarsys_sfra:int_emarsys:plugin_perimeterx:plugin_uniserv:plugin_dhl:plugin_mapp:plugin_sitemap:plugin_wishlists_buffalo:plugin_wishlists_snipes:plugin_service_cloud:int_payment_engine:lib_productlist:app_custom_buffalo:plugin_collectinstore:app_refapp:plugin_shipping_rates_ups:plugin_shipping_rates_dhl:int_service_cloud:app_storefront_base:app_arch:bc_job_components:plugin_stockreservation:plugin_data_deliveries:plugin_melissa:bc_transformation_layer:ds_console:plugin_arvato
export default class Main extends Namespace {
    getDefaultComponents () {
        return Object.assign({}, super.getDefaultComponents(), {
            mediaInteraction,
            gtm: gtm,
            emarsys: emarsys,
            trbo,
            // Header
            headerSlot,
            headerBrowserError,
            accountHeader,
            wishlistHeader,
            stickyHeader,
            stickyButton,
            stickyButtonTopBottom,
            stickyPanel,
            quickSearchForm,
            improvementQuickSearchForm,
            headerHamburger,
            mobileMenuSwitcher,
            headerSearchSwitcher,
            miniCart,
            cartLineItem,
            backButton,
            cookieHint,
            modal,
            modalContainer,
            toggleLink,
            alert,
            contestForm,

            carousel,
            heroCarousel,
            productBrand,
            expandBlock,
            spinner,
            recommendations,
            justAddedPopup,
            loginPopup,
            loginForm,
            swatchCarousel,
            localizationDialog,
            pickUpStation,
            pickUpStationModal,
            dataLink,
            livereach,
            classToggle,
            firstVisitPopup,
            breadcrumbs,
            initGTM,
            mainMenu,
            variationAttributesColor,
            imageStickyPanel,
            collapse,

            // Mobile menu components
            mobileMenu,
            mobileMenuToggle,

            // Footer
            backToTop,
            subscribeForm,
            unsubscribeForm,

            // Common form elements
            inputText,
            inputCheckbox,
            inputHidden,
            inputSelect,
            submitButton,
            inputTextarea,
            inputAutocomplete,
            globalCustomSelect,
            globalCustomSelectWrap,
            globalCustomSearchSelect,
            tooltip,
            checkoutConfirmation
        });
    }

    afterInit () {
        super.afterInit();

        const $window = $(window);

        $window.on('scroll touchmove', throttle(() => {
            this.eventEmitter.emit('scroll', {
                scrollTop: $window.scrollTop(),
                scrollDown: $window.scrollTop() + $window.height()
            });
        }, 200));

        $window.on('resize', debounce(() => {
            this.eventEmitter.emit('resize');
        }, 10));

        let oldHash = window.location.hash;

        $window.on('hashchange', () => {
            this.eventEmitter.emit('hashchange', {
                old: oldHash,
                new: window.location.hash
            });

            oldHash = window.location.hash;
        });

        $window.on('click touchstart', e => {
            this.eventEmitter.emit('globalClick', e.target);

            return true;
        });

        $window.on('orientationchange', debounce(() => {
            this.eventEmitter.emit('orientationChange');
        }, 10));
        $window.blur(() => this.eventEmitter.emit('globalWindowBlur'));

        let preloaderInit = () => {
            this.addComponents({ spinnerPreloader });
            this.initComponent($(document).find('[data-is-preloader="true"]'));
        };

        (document.readyState === 'complete') ? preloaderInit() : $window.on('load', preloaderInit);
    }
}
