import BaseHeaderHamburger from 'client/components/header/HeaderHamburger';
import { isSmall, isMedium } from 'client/utils/screendetector';
import $ from 'jquery';

const
    SELECTORS = {
        header: '.js-header',
        component: '.js-first-level-menu',
        mobileMenuTab: '.js-menu-tab',
        flyoutItem: '.js-menu-list-link-flyout',
        listWrapper: '.js-list-wrapper',
        menuWrapper: '.js-header-menu-wrapper',
        listItem: '.js-list-item',
        listShow: '.js-list-show',
        sectionTitle: '.js-menu-section-title',
        menuLevelTitle: '.js-menu-level-title',
        menuSection: '.js-menu-section',
        menuTitle: '.js-menu-title'
    },
    STATE_CLASSES = {
        sectionVisibility: 'h-visibility',
        mobileMenuTabActive: 'js-menu-tab-active b-tab-active',
        hideMobile: 'h-hide-sm h-hide-md',
        menuTitleActive: 'h-menu-title-active',
        highlightBuffaloLondon: 'h-buffalo-london-brand'
    };


export default class HeaderHamburger extends BaseHeaderHamburger {

    init () {
        super.init();

        this.$header = $(SELECTORS.header);
        this.sectionTitle = this.$el.find(SELECTORS.sectionTitle);
        this.$mobileMenuTab = this.$header.find(SELECTORS.mobileMenuTab);
        this.menuWrapper = this.$el.find(SELECTORS.menuWrapper);
        this.$menuLevelTitle = this.$el.find(SELECTORS.menuLevelTitle);
        this.menuSection = this.$el.find(SELECTORS.menuSection);
        this.menuTitle = this.$el.find(SELECTORS.menuTitle);

        this.currentLevel = 0;
        this.currentLink = '';
        this.currentCat = this.menuTitle.data('menu-title');
        this.prevCat = '';

        this.bindEvent('click', SELECTORS.mobileMenuTab, this.onMobileMenuTabClick);
        this.bindEvent('touchstart', SELECTORS.flyoutItem, (el, ev) => this.onTouchStart(el, ev));

        this.emitter.addListener('globalClick', this.onGlobalClick.bind(this));
    }

    onGlobalClick(target) {
        if (!$(target).parents(SELECTORS.component).length) {
            this.$selectedElement && this.$selectedElement.removeClass(this.config.activeClass);
            this.$selectedElement = null;
            this.selectedCategory = null;
        }
    }

    onTouchStart(el, ev) {
        if (isSmall() || isMedium()) {
            return;
        }

        let $el = $(el);

        ev && ev.stopPropagation();

        if (this.selectedCategory !== $el.attr('id')) {
            if (this.$selectedElement) {
                this.$selectedElement.closest('li').removeClass(this.config.activeClass);
            }

            this.$selectedElement = $el.closest('li').addClass(this.config.activeClass);
            this.selectedCategory = $el.attr('id');

            ev && ev.preventDefault();
        }
    }

    onMobileMenuTabClick(el) {
        const $el = $(el);
        const isBuffaloLondonBranch = $el.data('is-buffalo-london');

        if ($el.hasClass(STATE_CLASSES.mobileMenuTabActive)) {
            let href = $el.attr('data-tab-href');

            if (!isBuffaloLondonBranch) {
                href = $el.attr('data-home-page-href');
            }

            this.currentLink = href;
            this.relocate();
        } else {
            this.$mobileMenuTab.removeClass(STATE_CLASSES.mobileMenuTabActive);
            $el.addClass(STATE_CLASSES.mobileMenuTabActive);

            if (isBuffaloLondonBranch) {
                this.$header.addClass(STATE_CLASSES.highlightBuffaloLondon);
            } else {
                this.$header.removeClass(STATE_CLASSES.highlightBuffaloLondon);
            }

            if (this.currentLevel) {
                this.pullRight();
            }

        }
    }

    pullLeft (el, event) {
        if (!isSmall() && !isMedium()) {
            return;
        }

        event.preventDefault();

        const $el = $(el),
            nextListWrapper = $el.closest(SELECTORS.listItem, this.$el).find(SELECTORS.listWrapper).first(),
            listWrapper = $el.closest(SELECTORS.listWrapper, this.$el),
            catName = $el.data('cat'),
            href = $el.attr('href'),
            prevCat = listWrapper.data('cat');

        this.currentCat = catName;
        this.prevCat = prevCat;
        this.currentLink = href;

        this.currentLevel++;

        nextListWrapper.removeClass(STATE_CLASSES.hideMobile);

        if (this.currentLevel) {
            this.backBtnShow();
            this.sectionTitle.hide();
            this.menuSection.addClass(STATE_CLASSES.sectionVisibility);
        } else {
            this.sectionTitle.show();
            this.menuSection.removeClass(STATE_CLASSES.sectionVisibility);
        }

        this.menuWrapper.css('transform', `translateX(-${100 * this.currentLevel}%)`);
        this.$menuLevelTitle.get(0).scrollIntoView();
        this.menuTitle.text(this.currentCat);
        this.menuTitle.parent().addClass(STATE_CLASSES.menuTitleActive);
    }

    pullRight () {
        let delay = this.menuWrapper.css('transition-duration');
        const $targetList = this.$el.find(`${SELECTORS.listWrapper}[data-cat='${this.currentCat}']`);

        delay = (delay.substring(0, delay.length - 1)) * 1000;
        this.currentLevel--;

        if (this.currentLevel) {
            this.sectionTitle.hide();
            this.menuSection.addClass(STATE_CLASSES.sectionVisibility);
        } else {
            this.backBtnHide();
            this.sectionTitle.show();
            this.menuSection.removeClass(STATE_CLASSES.sectionVisibility);
        }

        this.menuWrapper.css('transform', `translateX(-${100 * this.currentLevel}%)`);

        setTimeout(function () {
            $targetList.addClass(STATE_CLASSES.hideMobile);
        }, delay);

        this.currentCat = this.prevCat;

        const $targetLink = this.$el.find(`${SELECTORS.listShow}[data-cat='${this.currentCat}']`),
            $prevList = $targetLink.closest(SELECTORS.listWrapper, this.$el);

        this.prevCat = $prevList.data('cat');
        this.currentLink = $targetLink.attr('href');

        this.menuTitle.text(this.currentCat);
        this.menuTitle.parent().removeClass(STATE_CLASSES.menuTitleActive);
    }
}
