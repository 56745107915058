import MainNamespace from 'vondutch/globalComponents/MainNamespace';

import addressBookMgr from 'vondutch/components/account/addressBook/AddressBookMgr';
import address from 'vondutch/components/account/addressBook/Address';
import newAddress from 'vondutch/components/account/addressBook/NewAddress';
import addEditForm from 'vondutch/components/account/addressBook/AddEditForm';

import addressValidator from 'vondutch/components/forms/AddressValidator';
import autocompleteAddress from 'client/components/forms/AutocompleteAddress';
import shippingMethod from 'client/components/checkout/shipping/ShippingMethod';
import shippingFinder from 'client/components/checkout/shipping/ShippingFinder';
import infoBlock from 'client/components/checkout/InfoBlock';
import suggestionAddressModal from 'vondutch/components/checkout/SuggestionAddressModal';
import countryField from 'vondutch/components/checkout/shipping/CountryField';
import stateField from 'vondutch/components/checkout/shipping/StateField';
import inputPostNumber from 'client/components/checkout/shipping/InputPostNumber';

const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    addressBookMgr,
    address,
    newAddress,
    addEditForm,

    addressValidator,
    autocompleteAddress,
    shippingMethod,
    shippingFinder,
    infoBlock,
    suggestionAddressModal,
    countryField,
    stateField,

    inputPostNumber
});

mainNamespace.init();
