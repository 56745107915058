import MainNamespace from 'vondutch/globalComponents/MainNamespace';

import confirmationRegistrationForm from 'client/components/forms/ConfirmationRegistrationForm';
import passwordStrength from 'client/components/forms/commonElements/PasswordStrength';
import checkoutApplicationEvents from 'client/components/order/confirmation/CheckoutApplicationEvents';

const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    confirmationRegistrationForm,
    passwordStrength,
    checkoutApplicationEvents
});

mainNamespace.init();
