import BaseQuickSearchForm from 'vondutch/components/header/QuickSearchForm';
import { CLASSES } from 'client/utils/globals';
import { appendParamToURL } from 'client/utils/url';
import $ from 'jquery';
import { isMedium, isSmall } from 'client/utils/screendetector';
import { scrollTo } from 'client/utils/common';

const SELECTORS = {
    searchInput: '.js-search-input',
    clearSearchBtn: '.js-clear-search',
    searchBtn: '.js-search-btn',
    suggestionsWrapper: '.js-suggestions-wrapper',
    defaultSuggestionsWrapper: '.js-default-suggestions-wrapper',
    suggestionsProductWrapper: '.js-suggestions-product-wrapper',
    suggestionsNoProductWrapper: '.js-suggestions-noproduct-wrapper',
    suggestionsNoProductsMsg: '.js-suggestions-noproducts-msg',
    searchFormWrap: '.js-search-form-wrapper',
    searchFormInner: '.js-search-form-inner',
    stickyHeader: '.js-sticky-inner',
    headerWrapper: '.js-header',
    searchContainer: '.js-search-container',
    headerBanner: '.js-header-banner'
};

const STATE_CLASSES = {
    searchShow: 'h-search-visible',
    searchShadow: 'c-search-form-inner--shadow',
    searchOpened: 'b-search--opened',
    closeBTtnShow: 'f-clear-search--show',
    headerSticked: 'h-header-sticky',
    searchSuggestionsOpened: 'h-search-suggestions--opened'
};

export default class ImprovementQuickSearchForm extends BaseQuickSearchForm {
    init() {
        super.init();

        this.$defaultSuggestionsWrapper = this.$el.find(SELECTORS.defaultSuggestionsWrapper);
        this.$suggestionsProductWrapper = this.$el.find(SELECTORS.suggestionsProductWrapper);
        this.$suggestionsNoProductWrapper = this.$el.find(SELECTORS.suggestionsNoProductWrapper);
        this.$suggestionsNoProductsMsg = this.$el.find(SELECTORS.suggestionsNoProductsMsg);

        this.isNoProducts = false;

        this.bindEvent('focus', SELECTORS.defaultSuggestionsWrapper, this.activateSearchInput);
        this.bindEvent('blur', SELECTORS.defaultSuggestionsWrapper, () => this.emitter.emit('enabledScroll'));

        if (isSmall() || isMedium()) {
            this.bindEvent('blur', SELECTORS.suggestionsWrapper, () => this.emitter.emit('disabledScroll'));
            this.bindEvent('blur', SELECTORS.defaultSuggestionsWrapper, () => this.emitter.emit('disabledScroll'));
        }
    }

    blurSearch(isOpen) {
        if (isOpen && (isSmall() || isMedium())) {
            if (this.suggestionNotEmpty && !this.suggestionsHidden) {
                setTimeout(() => this.recalculateHeight(), 200);
                return;
            }
            if (this.gtmEnabled) {
                this.emitter.emit('fireSearchSuggestionTag', this.emitter);
            }
            this.isSearchOpened = !isOpen;
        } else {
            this.$searchInput.attr({
                'placeholder': this.config.placeholderInactive,
                'aria-label': this.config.placeholderInactive
            });
        }
    }

    activateSearchInput () {
        this.isSearchOpened = true;

        this.$searchFormWrap.addClass(STATE_CLASSES.searchShow);
        this.$searchFormInner.addClass(`${STATE_CLASSES.searchShadow} ${STATE_CLASSES.searchInnerSize}`);
        if (this.suggestionNotEmpty) {
            this.showSuggestions();
        } else {
            this.showSuggestions(this.$defaultSuggestionsWrapper);
            this.toggleSugesstionProductWrapper();
        }

        this.$searchInput.attr({
            'placeholder': this.config.placeholderActive,
            'aria-label': this.config.placeholderActive
        });
        this.$headerWrapper.addClass(STATE_CLASSES.searchOpened);
    }

    onInputTyping (input, ev) {
        if (this.gtmEnabled && ((ev.keyCode ? ev.keyCode : ev.which) === 13)) {
            this.emitter.emit('fireSearchSuggestionTag', this.emitter);
        }

        let searchPhrase = this.$searchInput.val().trim();

        if (searchPhrase.length === 0) {
            this.isNoProducts = false;
            this.suggestionNotEmpty = false;
            this.$clearSearch.removeClass(STATE_CLASSES.closeBTtnShow);
        } else {
            this.$clearSearch.addClass(STATE_CLASSES.closeBTtnShow);
        }

        if (this.config.symbolsLength <= searchPhrase.length) {
            if (this.previousSearchPhrase === searchPhrase) {
                return;
            }

            this.emitter.emit('emarsys.search.term', searchPhrase);
            $.ajax({
                url: appendParamToURL(this.config.suggestUrl, this.config.searchParamName, searchPhrase),
                method: 'GET'
            }).then(data => {
                if (typeof data === 'string') {
                    const $html = $(data);

                    this.emitter.emit('namespace.component.initall', $html, {
                        onAfterInit: () => {
                            const $images = $html.find('img'),
                                imgCount = $images.length;

                            let imgLoaded = 0;

                            $images.on('load', () => {
                                if (++imgLoaded === imgCount) {
                                    this.hideSuggestions();
                                    this.emitter.emit('namespace.component.destroyall', this.$suggestionsWrapper);
                                    this.$suggestionsWrapper.empty().append($html);

                                    this.suggestionNotEmpty = true;
                                    this.showSuggestions();
                                }
                            });

                            if (this.gtmEnabled) {
                                this.emitter.emit('handleSearchSuggestion', $html, this.emitter);
                            }
                        }
                    });
                } else {
                    this.$suggestionsWrapper.empty();
                    this.hideSuggestions();
                    this.showSuggestions(this.$defaultSuggestionsWrapper);

                    if ((typeof data === 'object') && data.noProducts) {
                        this.isNoProducts = true;
                        this.suggestionNotEmpty = false;
                        this.toggleSugesstionProductWrapper();
                        this.$suggestionsNoProductsMsg.html(data.msg);
                    }
                }
            });
        } else {
            this.clearSuggestions();
            this.showSuggestions(this.$defaultSuggestionsWrapper);
            this.isNoProducts = false;
            this.suggestionNotEmpty = false;
        }

        this.previousSearchPhrase = searchPhrase;
    }

    clearSuggestions () {
        this.$suggestionsWrapper.empty();
        this.suggestionNotEmpty = false;
        this.isNoProducts = false;
        this.toggleSugesstionProductWrapper();
        this.hideSuggestions();
        this.showSuggestions(this.$defaultSuggestionsWrapper);

        if (this.gtmEnabled) {
            this.emitter.emit('fireSearchSuggestionTag', this.emitter);
        }
    }

    showSuggestions (wrapper) {
        var suggestionsWrapper = wrapper || this.$suggestionsWrapper;

        if (this.suggestionsHidden) {
            suggestionsWrapper.removeClass(CLASSES.hide);
            this.$searchFormInner.addClass(STATE_CLASSES.searchSuggestionsOpened);
            this.recalculateHeight();
            if (isSmall() || isMedium()) {
                this.emitter.emit('disabledScroll');

                if (this.isIOSDevice) {
                    scrollTo(this.$headerSearch, {
                        duration: 0
                    });
                }
            }
            this.suggestionsHidden = !this.suggestionsHidden;
        }
    }

    hideSuggestions () {
        if (!this.suggestionsHidden) {
            this.$suggestionsWrapper.addClass(CLASSES.hide);
            this.$defaultSuggestionsWrapper.addClass(CLASSES.hide);
            this.$searchFormInner.removeClass(STATE_CLASSES.searchSuggestionsOpened);
            this.suggestionsHidden = !this.suggestionsHidden;
            this.emitter.emit('enabledScroll');

            if (this.gtmEnabled) {
                this.emitter.emit('fireSearchSuggestionTag', this.emitter);
            }
        }
    }

    recalculateHeight() {
        const viewPortScrollTop = $(window).scrollTop();
        const defaultElementOffsetTop = this.$defaultSuggestionsWrapper.offset().top;

        this.viewPortHeight = $(window).innerHeight();
        this.$headerHeight = this.$stickyHeader.outerHeight();
        this.$headerBannerHeight = this.$headerBanner.outerHeight();
        this.$defaultHeaderHeight = defaultElementOffsetTop - viewPortScrollTop;

        let height = Math.round(this.viewPortHeight - this.$headerHeight);
        let defaultHeight = Math.round(this.viewPortHeight - this.$defaultHeaderHeight);

        if (!this.isHeaderSticky) {
            height = Math.round(height - this.$headerBannerHeight);
        }

        const isMobile = isSmall() || isMedium();

        if (isMobile) {
            this.$suggestionsWrapper.css('min-height', height);
            this.$defaultSuggestionsWrapper.css('min-height', defaultHeight);
        } else {
            this.$suggestionsWrapper.css('min-height', '610px');
            this.$defaultSuggestionsWrapper.css('min-height', '610px');
        }
    }

    toggleDefaultSuggestionWrapper(show) {
        if (show) {
            this.$defaultSuggestionsWrapper.removeClass(CLASSES.hide);
            this.$suggestionsWrapper.addClass(CLASSES.hide);
        } else {
            this.$defaultSuggestionsWrapper.removeClass(CLASSES.hide);
            this.$suggestionsWrapper.addClass(CLASSES.hide);
        }
    }

    toggleSugesstionProductWrapper() {
        if (this.isNoProducts) {
            this.$suggestionsProductWrapper.addClass(CLASSES.hide);
            this.$suggestionsNoProductWrapper.removeClass(CLASSES.hide);
        } else {
            this.$suggestionsProductWrapper.removeClass(CLASSES.hide);
            this.$suggestionsNoProductWrapper.addClass(CLASSES.hide);
        }
    }
}
