import BaseLocalizationDialog from 'client/components/LocalizationDialog';
import $ from 'jquery';
import templatesManager from 'client/utils/templatesManager';
import { hasCookie, getCookie } from 'client/utils/cookie';
import { COOKIE } from 'client/utils/globals';

const SELECTORS = {
    localizationFlag: '.js-localization-flag',
    localizationForm: '.js-localization-form',
    localizationLocaleSelector: '#localizationLocaleSelector',
    countrySelector: '.js-custom-country-localization-list',
    customSelectItem: '.js-custom-select-item',
    languageWrapper: '.js-localization-language-wrapper',
    nativeCountrySelect: '.js-localization-country-select',
    radioBtn: '.js-language-radio-button',
    searchCountryInput: '#searchCountrySelectorInput',
    searchCountrySelect: '.js-country-select',
    searchCountryHeader: '.js-custom-select-header',
    searchCountryTitle: '.js-custom-select-title'
};

const STATE_CLASSES = {
    activeFlag: 'h-item-selected'
};

const LOCALE = [
    'language',
    'country'
];

export default class LocalizationDialog extends BaseLocalizationDialog {
    init () {
        super.init();

        this.language = this.context.currentLocale.language;
    }

    showModal(byClick, el, e) {
        super.showModal(byClick, el, e);

        let $currentActiveLocale = $(`[data-value="${this.context.currentLocale.countryCode}"]`);

        this.$searchCountryInput = this.$modalContainer.find(SELECTORS.searchCountryInput);
        this.$searchCountrySelect = this.$modalContainer.find(SELECTORS.searchCountrySelect);
        this.$searchCountryHeader = this.$modalContainer.find(SELECTORS.searchCountryHeader);
        this.$flags = $(SELECTORS.localizationFlag);
        this.$nativeCountrySelect = this.$modalContainer.find(SELECTORS.nativeCountrySelect);
        this.$countrySelector = this.$modalContainer.find(SELECTORS.countrySelector);
        this.$modalContainer.on('click', SELECTORS.localizationFlag, this.onFlagClick.bind(this));
        this.$nativeCountrySelect.on('change', () => this.onSelectChanged());
        this.$searchCountryInput = this.$modalContainer.find(SELECTORS.searchCountryInput);
        this.keySearchCountry(
            SELECTORS.searchCountryHeader,
            SELECTORS.searchCountryInput,
            SELECTORS.searchCountrySelect,
            SELECTORS.searchCountryTitle,
            `${SELECTORS.countrySelector} li`
        );
        // Highlight geolocation detected country if some present in flag list or  Highlight default detected country
        if (this.detectedCountry && this.context.countries[this.detectedCountry.toUpperCase()]) {
            this.$nativeCountrySelect.val(this.detectedCountry.toUpperCase());
            $currentActiveLocale = $(`[data-value="${this.detectedCountry.toUpperCase()}"]`);
        }

        this.addActiveClass($currentActiveLocale);

        this.emitter.emit('countryChange', this, this.$nativeCountrySelect);
    }

    async processModalAutoOpen() {
        let localizationCookie = hasCookie(COOKIE.localizationSettings) ? getCookie(COOKIE.localizationSettings) : '';
        let hidePopUpCookie = hasCookie(COOKIE.hideLocalizationDialog) ? getCookie(COOKIE.hideLocalizationDialog) : '';
        let customerCountry = await this.detectCustomerCountry();
        let siteCountryCode = this.config.templateAttrs.currentLocale.countryCode.toLowerCase();

        let isAutoOpen = !localizationCookie && !hidePopUpCookie && siteCountryCode !== customerCountry;

        if (isAutoOpen) {
            this.detectedCountry = customerCountry;
            this.showModal(false, null, null);
        } else {
            this.notifyDone();

            this.emitter.addListener('popupNotify', () => {
                this.notifyDone();
            });
        }
    }

    onFlagClick(event) {
        let $el = $(event.currentTarget);
        let options = this.$countrySelector.find(SELECTORS.customSelectItem);
        let selectedCountryCode = $el.data('value');

        this.removeAllActiveClass();
        this.addActiveClass($el);

        for (let option of options) {
            let $option = $(option);

            if ($option.data('value') === selectedCountryCode) {
                $option.trigger('click');
                break;
            }
        }
    }

    onSelectChanged() {
        this.removeAllActiveClass();

        let value = this.$nativeCountrySelect.val();
        let $el = this.$modalContainer.find(`[data-value="${value}"]`);

        if ($el.length > 0) {
            this.addActiveClass($el);
        }
    }

    /**
     * @param {Component} self
     * @param {jQuery} el
     */
    countrySelectorChangeHandler(self, el) {
        let $el = $(el),
            selectedCountryCode = $el.val(),
            countries = self.context.countries,
            selectedCountry = countries[selectedCountryCode];

        if (!this.language) {
            this.language = self.context.currentLocale.language;
        }

        selectedCountry.locales.map(locale => {
            locale.selected = locale.value.split('_')[0] === this.language;

            return locale;
        });

        self.replaceHtml(
            templatesManager.templates.localizationDialogCountrySpecificTemplate({
                locales: selectedCountry.locales
            }),
            self.$modalContainer.find(SELECTORS.languageWrapper)
        );

        self.$modalContainer.on('change', SELECTORS.radioBtn, (event) => {
            this.language = $(event.currentTarget).val().split('_')[0];
        });

        self.removeAllActiveClass();

        let $selectedFlag = $(SELECTORS.localizationForm)
            .find(SELECTORS.localizationFlag + '[data-value="' + selectedCountryCode + '"]');

        if ($selectedFlag.length) {
            self.addActiveClass($selectedFlag);
        }
    }

    trackStoreSwitch() {
        const $localizationLocaleSelector = this.$modalContainerSafe.find(SELECTORS.localizationLocaleSelector);

        if (!$localizationLocaleSelector.length) {
            return;
        }

        const aLocale = $localizationLocaleSelector.val().toUpperCase().split('_');

        for (let i = (aLocale.length - 1); i >= 0; i--) {
            const data = {
                eventCategory: 'store switch',
                eventAction: LOCALE[i],
                eventLabel: aLocale[i]
            };

            this.emitter.emit('gtmEvent', data);
        }
    }

    removeAllActiveClass() {
        for (let i = 0; i < this.$flags.length; i++) {
            $(this.$flags[i]).removeClass(STATE_CLASSES.activeFlag);
        }
    }

    addActiveClass($el) {
        $el.addClass(STATE_CLASSES.activeFlag);
    }

    destroy() {
        this.$modalContainer.off('click', SELECTORS.localizationFlag);
        this.$nativeCountrySelect.off('change');
        this.$searchCountryInput.off('keyup');
        this.$searchCountrySelect.off('keydown');
        this.$searchCountryHeader.off('click');

        super.destroy();
    }

    keySearchCountry(searchHeader, searchField, selectWrapper, searchTitle, searchElements) {
        var query;

        $(searchHeader).on('click', function() {
            $(searchElements).show();

            query = $(this).find(searchTitle).text().trim();

            if (!$(selectWrapper).hasClass('h-custom-select-opened')) {
                $(searchField).attr('placeholder', query);
                $(searchField).focus().val('');
            }
        });


        $(searchField).on('keyup', function(e) {
            e.preventDefault();

            query = $(this).val().toLowerCase();

            if (query) {
                $.each($(searchElements), function() {
                    let title = $(this).text().toLowerCase().trim();

                    if (title.indexOf(query) === 0) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            } else {
                $(searchElements).show();
            }
        });

        $(selectWrapper).on('keydown', function(e) {
            e.stopPropagation();
        });
    }
}
